// CLASSFX
export const UPDATE_CLASSFX_SCENETYPE = 'UPDATE_CLASSFX_SCENETYPE';

export const UPDATE_CLASSFX_MAINTEACHERVOICE = 'UPDATE_CLASSFX_MAINTEACHERVOICE';

export const UPDATE_CLASSFX_MINORTEACHERVOICE = 'UPDATE_CLASSFX_MINORTEACHERVOICE';

export const UPDATE_CLASSFX_CLASSVOICE = 'UPDATE_CLASSFX_CLASSVOICE';

export const UPDATE_CLASSFX_EMAILURL = 'UPDATE_CLASSFX_EMAILURL';
