import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'next/router';
import Navigator from '../common/Navigator';
import NavigatorOnlyLogo from '../common/NavigatorOnlyLogo';
import TalFooter from '../common/Footer';
import SideBar from '../common/Sidebar';
import { StyleLayout as LayoutWrapper } from '../../styled-components/global-css';
import solutionList from '@/data/expand_solution.json';
import productionList from '@/data/openAbility.json';

const TalLayout = ({ children, router }) => {
  // 在特殊url中需要隐藏模块
  const [specialUrlIsHideModule, setSpecialUrlIsHideModule] = useState(true);
  const hasFooter =
    router.route !== '/MathGPT' &&
    router.route !== '/contact-us' &&
    router.route !== '/virtual-assistant' &&
    router.route !== '/evaluate-result' &&
    router.route !== '/dataset-privacy' &&
    router.route !== '/dataset-auth' &&
    router.route !== '/speaker-separation' &&
    router.route !== '/photo-search' &&
    router.route !== '/video_test' &&
    router.route !== '/video-Interpretation' &&
    router.route !== '/video_test' &&
    router.route !== '/androidDown/intelligentExercise' &&
    router.route !== '/androidDown/suoyiran' &&
    router.route !== '/androidDown/liveAssistant' &&
    router.route !== '/androidDown/workCorrect' &&
    router.route.indexOf('/account/') < 0;
  const hasNavigator =
    router.route !== '/MathGPT' &&
    router.route !== '/speaker-separation' &&
    router.route !== '/androidDown/intelligentExercise' &&
    router.route !== '/androidDown/suoyiran' &&
    router.route !== '/androidDown/liveAssistant' &&
    router.route !== '/androidDown/workCorrect' &&
    router.route !== '/account/login' &&
    router.route !== '/video_test' &&
    router.route !== '/photo-search' &&
    router.route !== '/video_test' &&
    router.route !== '/video-Interpretation';

  // 虚拟助教在iframe中隐藏haeder和分享
  useEffect(() => {
    if (process.title === 'browser') {
      if (router.route === '/virtual-assistant') {
        setSpecialUrlIsHideModule(window.self !== window.top);
        console.log('specialUrlIsHideModule', specialUrlIsHideModule);
      } else if (router.route === '/MathGPT') {
        setSpecialUrlIsHideModule(true);
      } else {
        setSpecialUrlIsHideModule(false);
      }
    }
  }, [router.route, specialUrlIsHideModule]);

  const isNavigatorOnlyLogo =
    router.route === '/account/register' ||
    router.route === '/account/forget-password' ||
    router.route === '/account/success';
  let isNavigatorAlwaysActive = true;
  if (
    router.route == '/' ||
    (router.route === '/solution' && solutionList[router.query.name]) ||
    (router.route === '/product' &&
      productionList.find((p) => p.name === router.query.name)) ||
    router.route === '/news-info' ||
    router.route === '/dataset'
  ) {
    isNavigatorAlwaysActive = false;
  }
  return (
    <LayoutWrapper>
      {hasNavigator && isNavigatorOnlyLogo && !specialUrlIsHideModule && (
        <NavigatorOnlyLogo />
      )}
      {hasNavigator && !isNavigatorOnlyLogo && !specialUrlIsHideModule && (
        <Navigator isNavigatorAlwaysActive={isNavigatorAlwaysActive} />
      )}
      {!specialUrlIsHideModule && <SideBar />}
      {children}
      {hasFooter && <TalFooter />}
    </LayoutWrapper>
  );
};

TalLayout.propTypes = {
  children: PropTypes.node
};

// 后续会从redux中获取数据
export default connect(null, null)(withRouter(TalLayout));
