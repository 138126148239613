import React from 'react';
import less from './Footer.less';
import whichEnvironment from '@/common/utils/whichEnvironment';

let paasUrl = '';
/* eslint-disable indent */
switch (whichEnvironment()) {
  case 'CODING':
    paasUrl = 'http://localhost:8080';
    break;
  case 'DEV':
    paasUrl = 'https://gateway-test-multi.facethink.com';
    break;
  case 'TEST':
    paasUrl = 'https://gateway-test-multi.facethink.com';
    break;
  case 'RELEASE':
    paasUrl = 'https://openai.100tal.com';
    break;
  default:
    break;
}

function gotoCooperationPage(name) {
  window.open(`/${name}`);
}

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.handleGoToFreeTaste = this.handleGoToFreeTaste.bind(this);
  }

  handleGoToFreeTaste() {
    if (this.state.hasLoggedin) {
      window.open(paasUrl + '/console/manage/homepage');
    } else {
      window.open('/account/login');
    }
  }

  render() {
    return (
      <div className={less.container}>
        <div className={less.FooterTop}>
          <div className={less.left}>
            <img className={less.logo} src="https://openplantform.oss-cn-beijing.aliyuncs.com/logo/logo-03%402x.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5184067679&Signature=7sZGypP%2Bx%2BI5Bi5YlSggFifioww%3D" alt="tal logo" />
          </div>
          <div className={less.middle}>
            <div className={less.item1}>
              <div className={less.title}>
                资源
              </div>
              <div className={less.ai_block1}>
                <a href="https://openai.100tal.com/documents/article/page?fromWhichSys=console&id=27" className={less.ai_item} target="_bank">接入指南</a>
              </div>
              <div className={less.ai_block1}>
                <a href="https://openai.100tal.com/documents/article/page?fromWhichSys=admin&id=74" className={less.ai_item} target="_bank">API文档</a>
              </div>
              <div className={less.ai_block1}>
                <a href="https://openai.100tal.com/documents/article/page?fromWhichSys=admin&id=80" className={less.ai_item} target="_bank">常见问题</a>
              </div>
            </div>
            <div className={less.item2}>
              <div className={less.title}>
                快速入口
              </div>
              <div className={less.ai_block}>
                <a className={less.ai_item} target="_bank" onClick={() => gotoCooperationPage('cooperation')}>合作咨询</a>
              </div>
              <div className={less.ai_block}>
                <a href="https://openai.100tal.com/documents/article/page?fromWhichSys=console&id=104" className={less.ai_item1} target="_bank">服务协议</a>
              </div>
              <div className={less.ai_block}>
                <a href="https://openai.100tal.com/documents/article/page?fromWhichSys=console&id=107" className={less.ai_item1} target="_bank">隐私协议</a>
              </div>
            </div>
          </div>
          <div className={less.right}>
            <div className={less.qrCodeBox}>
              <img className={less.qrCode} src="https://openplantform.oss-cn-beijing.aliyuncs.com/index/indexerweima.jpg?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5190744053&Signature=SYto2IH6lC9oVFXZ0%2FwSJEI9Otk%3D" alt="tal QR code" />
              <span className={less.aiExperience}>AI体验小程序</span>
            </div>
            <div className={less.qrCodeBox}>
              <img className={less.qrCode} src="https://openplantform.oss-cn-beijing.aliyuncs.com/index/aigongzhonghao.png?OSSAccessKeyId=LTAI4FvBYgUM9wyFK4wa9nvy&Expires=5216558136&Signature=IWxOOLTfbVx%2FEsC0ZgGcdXtgTwo%3D" alt="tal QR code" />
              <span className={less.aiExperience}>AI官方公众号</span>
            </div>
          </div>
        </div>
        <div className={less.FooterBottom}>
          <p className={less.bottom}>
          Copyright © 2019-2021 好未来版权所有 北京学而思网络科技有限公司 <a href="https://beian.miit.gov.cn/">京ICP备09042963号-32</a>
          </p>
        </div>
      </div>
    );
  }
}

export default Footer;
