import App from 'next/app';
import React from 'react';
import { Provider } from 'react-redux';
import { NextSeo } from 'next-seo';
import NProgress from 'nprogress';
import Router from 'next/router';
import withReduxStore from '../lib/with-redux-store';
import TalLayout from '../components/layout/Layout';
import 'antd/dist/antd.css';
import 'nprogress/nprogress.css';
import metaInfo from '@/data/meta';
import './global.css';

Router.events.on('routeChangeStart', () => {
  NProgress.configure({ showSpinner: false });
  NProgress.start();
});
Router.events.on('routeChangeComplete', () => NProgress.done());
Router.events.on('routeChangeError', () => NProgress.done());

// 非node渲染层并且是正式环境
if (
  process.title === 'browser' &&
  (process.env.NODE_ENV === 'production' || process.env.NODE_ENV === 'beta')
) {
  if (window) {
    const isRelease = window.location.href.indexOf('ai.100tal.com') !== -1;
    // eslint-disable-next-line global-require
    const Aegis = require('aegis-web-sdk');
    const aegis = new Aegis({
      id: 'mZyblhLOEgm2zDVZLb', // 应用ID，即上报ID
      uin: isRelease ? 'release' : 'test',
      // reportApiSpeed: true, // 接口测速
      // reportAssetSpeed: true, // 静态资源测速
      spa: true, // spa 应用页面跳转的时候开启 pv 计算
      beforeRequest(logData) {
        // 排除APP下载页面重复上报，改为首页点击时上报
        if (window.location.href.indexOf('downloadCenter#') > -1) {
          return false;
        }
        if (logData.logs) {
          if (logData.logs.level === '8') return false; // promise 错误
          if (logData.logs.level === '16' && /status:\s*401/.test(logData.logs.msg)) { // ajax 401错误
              return false;
          }
        }
        return logData;
      },
      api: {
        retCodeHandler(data) {
          // 注意这里拿到的data是string类型，如果需要对象需要手动parse下
          console.log('retCodeHandler 2023-1-16', data);
          try {
            data = JSON.parse(data);
          } catch (e) {
            console.log('解析data报错', e);
          }
          return {
            // isErr 如果是 true 的话，会上报一条 retcode 异常的日志。
            // https://wiki.zhiyinlou.com/pages/viewpage.action?pageId=10646020
            isErr: [
              4000001, 4040002, 5000000, 5000001, 5000002, 4000101, 4000201
            ].includes(data.body.code),
            code: data.body.code
          };
        }
      }
    });
    window.$Aegis = aegis;
  }
}

class MyApp extends App {
  render() {
    const { Component, pageProps, reduxStore } = this.props;
    const Layout = Component.layout || TalLayout;
    return (
      <Provider store={reduxStore}>
        <Layout>
          <NextSeo {...metaInfo} />
          <Component {...pageProps} />
        </Layout>
      </Provider>
    );
  }
}

export default withReduxStore(MyApp);
